import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

export const pageQuery = graphql`
  query Projects {
    site {
      siteMetadata {
        keywords
      }
    }
  }
`

const Projects = ({ data, location }) => {
  const { keywords} = data.site.siteMetadata

  return (
    <Layout location={location} title="Projects" showMenu>
      <SEO title="Projects" keywords={keywords}/>
    </Layout>
  )
}

export default Projects